import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import agenda_rows from './mock.json';
import styles from './AgendaCustom.module.css';
import AgendaForDesktop from './AgendaForDesktop';

const AgendaTime = (props) => {
  const { time = '', title = '', subtitle = '', isTable = false } = props;
  return (
    <Box sx={{ pb: 1, pt: 1 }}>
      <Box sx={{ display: 'flex' }}>
        <Grid container>
          {time && (
            <Grid item xs={12} sm={12} md={!title ? 12 : 2} className={styles['time-container']}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  p: '5px 16px',
                }}
              >
                {time}
              </Typography>
            </Grid>
          )}
          {title && (
            <Grid item xs={12} sm={12} md={10} sx={{ textAlign: 'left' }} className={isTable && styles['time-container']}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: '24px',
                  pb: { xs: 1, sm: 0 },
                }}
              >
                {title}
              </Typography>
            </Grid>
          )}
          {subtitle && (
            <Grid item xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                }}
              >
                {subtitle}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
const SpeakerInfo = (props) => {
  const { name = '', designation = '', company = '' } = props;
  return (
    <Box sx={{ pb: 1 }}>
      <Typography sx={{ fontWeight: 'bold', fontSize: '20px', color: '#fff' }}>{name}</Typography>
      <Typography sx={{ fontWeight: '500', fontSize: '16px', color: '#8B7A83' }}>{designation}</Typography>
      {company && <Typography sx={{ fontWeight: '400', fontSize: 17, color: '#FFF' }}>{company}</Typography>}
    </Box>
  );
};
const AgendaRow = (props) => {
  const { programs = [] } = props;
  return (
    <Box>
      {programs.map((program, pid) => {
        return (
          <Grid
            container
            key={pid}
            sx={{
              p: 2,
              textAlign: 'left',
            }}
          >
            <Grid item xs={12} md={4}>
              <Box>
                {program?.speakers?.length > 0 &&
                  program.speakers.map((speaker, sid) => {
                    return <SpeakerInfo key={sid} {...speaker} />;
                  })}
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ borderBottom: pid < programs.length - 1 && '0.3px solid #8B7A83', pb: '30px' }}>
              <Typography
                sx={{
                  color: { xs: '#FFF', sm: '#646464' },
                  fontSize: '20px',
                  fontWeight: 500,
                }}
              >
                {program?.prefix && (
                  <>
                    <strong>{program?.prefix}</strong>
                    <br />
                  </>
                )}
                {program?.name}
              </Typography>
            </Grid>

            <Grid item sx={{ display: { xs: 'none', sm: 'block' } }} xs={12} md={4}>
              {program?.description}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

const AgendaCustom = (props) => {
  // const { agenda_rows = [] } = props;

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return (
    <div id='agenda'>
      {mobile ? (
        <Grid container sx={{ p: '25px' }} className={styles['agenda-container']}>
          <Typography variant='h3' sx={{ color: '#FFF', pb: 2, fontSize: '30px', fontWeight: 'bold' }}>
            Agenda
          </Typography>
          {agenda_rows.map((row, rowid) => (
            <Box
              key={rowid}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {(row?.time || row?.title) && (
                <AgendaTime time={row?.time} title={!row?.speakers && row?.title ? row?.title : ''} subtitle={row?.subtitle ? row.subtitle : ''} />
              )}
              <Box sx={{ p: 0 }}>{row?.programs && <AgendaRow programs={row?.programs} />}</Box>
            </Box>
          ))}
        </Grid>
      ) : (
        <Grid container sx={{}} className={styles['agenda-container']}>
          <Typography variant='h3' sx={{ color: '#FFF', p: '30px 0 20px 6%', marginTop: '40px', fontSize: '58px', fontWeight: 'bold' }}>
            Agenda
          </Typography>
          <AgendaForDesktop />
        </Grid>
      )}
    </div>
  );
};

export default AgendaCustom;
