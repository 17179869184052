import { useState, useEffect } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { multiplaiLogo } from '../../assets/Images/Images';
import NavMenu from './NavMenu';
// import SignUp from '../SignUp/SignUpPopup';

import { NavHashLink } from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#000000 !important',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#000000',
    transition: 'background-color 0.3s',
  },
  brandLogo: {
    marginLeft: (props) => (props.mobile ? '20px' : '0'),
    marginRight: '10px',
    '&:focus-visible': {
      // background: '#d61b32',
      boxShadow: 'none',
      outline: '2px dotted #FFF',
      outlineOffset: 2,
    },
  },
  headerLinks: {
    display: 'flex',
    textAlign: 'center',
  },
  links: {
    // width: '130px',
    marginRight: '25px',
    textDecoration: 'none',
    //   padding: '0 20px',
    color: '#FFF',
    // fontWeight: '600',
    '&:focus-visible': {
      // background: '#d61b32',
      boxShadow: 'none',
      outline: '2px dotted #FFF',
      outlineOffset: 2,
      color: '#fff',
    },
  },
  headerBtns: {
    display: 'flex',
    '& div:first-child': {
      // paddingRight: '20px',
    },
  },
  loginBtn: {
    fontSize: 14,
    padding: '12px 35px',
    borderRadius: 50,
    background: '#FFF',
    color: '#333333',
    minWidth: 125,
    // fontFamily: 'Helvetica',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      background: '#d61b32',
      boxShadow: 'none',
    },
    '&:focus-visible': {
      background: '#d61b32',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:disabled': {
      background: '#D6D6D6',
      color: '#333333',
      cursor: 'default',
    },
  },
  customButton: {
    fontSize: 14,
    padding: '12px 35px',
    borderRadius: 50,
    background: '#333333',
    color: '#fff',
    minWidth: 125,
    // fontFamily: 'Helvetica',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      background: '#d61b32',
      boxShadow: 'none',
    },
    '&:focus-visible': {
      background: '#d61b32',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:disabled': {
      background: '#D6D6D6',
      color: '#333333',
      cursor: 'default',
    },
  },
  userName: {
    color: '#000000',
    fontSize: 19,
    // fontFamily: 'HelveticaforTargetRegular',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:focus-visible': {
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  logout: {
    '&:focus-visible': {
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  badgeContent: {
    '.MuiBadge-badge': {
      height: 13,
      minWidth: 13,
    },
  },
  notificationButton: {
    border: 'none',
    background: 'none',
    '&:focus-visible': {
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
  activeLink: {
    // width: '130px',
    marginRight: '25px',
    textDecoration: 'none',
    color: '#FFF',
    fontWeight: 900,
    '&:focus-visible': {
      outline: '2px dotted #FFF',
      outlineOffset: 2,
      color: '#FFF',
    },
  },
}));

const NavBar = ({ loggedInUser, userSuccess, visibleComponent }) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const navigate = useNavigate();
  let listener = null;
  const [scrollState, setScrollState] = useState('top');

  const location = useLocation();
  const { pathname, hash } = useLocation();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    listener = document.addEventListener('scroll', (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      const { pathname } = location;
      if (scrolled >= 30 || pathname === '/register' || pathname === '/profile') {
        if (scrollState !== 'down') {
          setScrollState('down');
        }
      } else {
        if (scrollState !== 'top') {
          setScrollState('top');
        }
      }
    });
    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [scrollState]);

  const classes = useStyles({ mobile, scrollState });

  useEffect(() => {
    if (pathname !== '' && !hash) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 200);
    }
    if (hash) {
      setTimeout(() => {
        let el = document.getElementById(hash.replace('#', ''));
        if (el) {
          const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
          const yOffset = -90;
          window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }
      }, 1800);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  return (
    <>
      {mobile && pathname !== '/spot' ? (
        // for mobile view
        <AppBar elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolBar} sx={{ padding: { sm: '16px 0', md: '16px 78px 13px !important' } }}>
            <NavHashLink to='/#home' className={classes.brandLogo} scroll={(el) => scrollWithOffset(el)} style={{ marginLeft: mobile ? '-17px' : '0' }}>
              <img src={multiplaiLogo} alt='Target Logos' style={{ cursor: 'pointer' }} onClick={() => navigate('/')} width={mobile ? '80%' : '100%'} />
            </NavHashLink>
            <NavMenu loggedInUser={loggedInUser} userSuccess={userSuccess} />
          </Toolbar>
        </AppBar>
      ) : (
        //for desktop view
        <AppBar elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolBar} sx={{ padding: { sm: '16px 0', md: '0 100px !important' } }}>
            <div style={{ paddingLeft: '15px', marginLeft: pathname === '/spot' && '-10px' }}>
              {/* below h1 is for keyboard users */}
              <h1 className={classes.visuallyHidden}>Target Multiplai </h1>
              <NavHashLink to='/#home' className={classes.brandLogo} scroll={(el) => scrollWithOffset(el)}>
                <img src={multiplaiLogo} alt='Multiplai logo' style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
              </NavHashLink>
            </div>

            {pathname !== '/spot' && (
              <div className={classes.headerLinks}>
                <NavHashLink
                  to='/#home'
                  className={location.hash === '#home' || (location.pathname === '/' && location.hash === '') ? classes.activeLink : classes.links}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Home
                </NavHashLink>
                <NavHashLink
                  to='/#agenda'
                  // style={{ width: '180px' }}
                  className={location.hash === '#agenda' ? classes.activeLink : classes.links}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Agenda
                </NavHashLink>
                <NavHashLink
                  to='/pastspeakers'
                  // style={{ width: '180px' }}
                  className={location.pathname === '/pastspeakers' ? classes.activeLink : classes.links}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Past speakers
                </NavHashLink>
                <NavHashLink
                  to='/gallery'
                  className={location.pathname === '/gallery' ? classes.activeLink : classes.links}
                  scroll={(el) => scrollWithOffset(el)}
                >
                  Gallery
                </NavHashLink>
                <NavHashLink
                  to='/faqs'
                  className={location.pathname === '/faqs' ? classes.activeLink : classes.links}
                  scroll={(el) => scrollWithOffset(el)}
                  style={{ textAlign: 'left' }}
                >
                  FAQs
                </NavHashLink>
              </div>
            )}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default NavBar;
