import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
// import SignUp from '../SignUp/SignUpPopup';
// import Login from '../Login/Login';
import { NavHashLink } from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    //backgroundColor: 'red !important',
    top: '-10px !important',
    transition: 'height 1s ease !important',
    '& .MuiPaper-root': {
      maxWidth: '100%',
      left: '0 !important',
      filter: 'none !important',
      transition: 'height 1s ease !important',
      background: '#000000 !important',
    },
    '& .MuiMenuItem-root': {
      '-webkit-justify-content': 'flex-end !important',
      paddingRight: '1.7rem',
      minHeight: 'auto',
      transition: 'height 1s ease !important',
    },
  },
  menuIcon: {
    border: '1px solid rgba(0,0,0,.1)',
    color: '#FFF',
    padding: 2,
    width: '2.8rem !important',
  },
  menuLinks: {
    textDecoration: 'none',
    color: '#FFF',
  },
  loginBtn: {
    paddingTop: '20px',
  },
  customButton: {
    fontSize: 14,
    padding: '14px 26px',
    marginBottom: 11,
    borderRadius: 50,
    background: '#FFF',
    // color: '#fff',
    minWidth: 125,
    fontFamily: 'Helvetica',
    border: '1px solid #000',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      background: '#d61b32',
      boxShadow: 'none',
    },
  },
}));
export default function NavMenu({ loggedInUser, logOut, handleNotifications, userSuccess }) {
  // const [openLogin, setOpenLogin] = React.useState(false);
  // const [openSignUp, setopenSignUp] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title='Mobile NavBar Menu'>
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuIcon fontSize='large' className={classes.menuIcon} />
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        className={classes.menuContainer}
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1.5,
            width: '100%',
            left: 0,
          },
        }}
        transitionDuration={100}
        //TransitionComponent={Collapse}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <NavHashLink to='/#home' className={classes.menuLinks} scroll={(el) => scrollWithOffset(el)}>
            Home
          </NavHashLink>
        </MenuItem>
        <MenuItem>
          <NavHashLink to='/#agenda' className={classes.menuLinks} scroll={(el) => scrollWithOffset(el)}>
            Agenda
          </NavHashLink>
        </MenuItem>
        <MenuItem>
          <NavHashLink to='/pastspeakers' className={classes.menuLinks} scroll={(el) => scrollWithOffset(el)}>
            Past speakers
          </NavHashLink>
        </MenuItem>
        <MenuItem>
          <NavHashLink to='/register' className={classes.menuLinks} scroll={(el) => scrollWithOffset(el)}>
            Register
          </NavHashLink>
        </MenuItem>
        <MenuItem>
          <NavHashLink to='/gallery' className={classes.menuLinks} scroll={(el) => scrollWithOffset(el)}>
            Gallery
          </NavHashLink>
        </MenuItem>
        <MenuItem>
          <NavHashLink to='/faqs' className={classes.menuLinks} scroll={(el) => scrollWithOffset(el)}>
            FAQ
          </NavHashLink>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
