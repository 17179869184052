import React, { useEffect, useState } from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GalleryTabs from './GalleryTabs';
import galleryImages from '../../SiteData/Gallery/GalleryImages.json';
import galleryImages2023 from '../../SiteData/Gallery/GalleryImages2023.json';
import galleryImages2024 from '../../SiteData/Gallery/GalleryImages2024.json';
import MultiplaiVideos from '../../SiteData/Gallery/MultiplaiVideos';
import MultiplaiVideos2023 from '../../SiteData/Gallery/MultiplaiVideos2023';
import MultiplaiVideos2024 from '../../SiteData/Gallery/MultiplaiVideos2024';
import { galleryDesktopBanner, galleryMobileBanner } from '../../assets/Images/Images';

const useStyles = makeStyles((theme) => ({
  containerHeading: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '55px !important',
    // fontFamily: 'HelveticaforTargetBold !important',
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '26px !important',
    },
  },
  whiteLine: {
    display: 'block',
    margin: '20px auto',
  },
  container: {
    width: '100%',
    // margin: '0 auto',
    paddingTop: '3rem',
    // marginTop: '4rem',
  },
  activeTabStyles: {
    fontSize: '22px',
    color: '#ffffff',
    cursor: 'pointer',
    background: 'linear-gradient(180deg, #cc0000 0%, #8d255d)',
    borderRadius: '5px',
    marginRight: '30px',
    fontWeight: 100,
    padding: '10px 30px',
    // padding: '10px 36px',
    // color: '#353743',
    // background: '#FFF',
    // borderRadius: 25,
    // cursor: 'pointer',
    // // fontFamily: 'HelveticaforTargetRegular',
    // fontWeight: 400,
    // border: 'none',
    // fontSize: 16,
    [theme.breakpoints.down('md')]: {
      marginBottom: '20px !important',
    },
    '&:focus-visible': {
      borderRadius: 50,
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },

  tabStyles: {
    fontSize: '22px',
    color: '#ffffff',
    cursor: 'pointer',
    borderRadius: '5px',
    marginRight: '30px',
    fontWeight: 100,
    background: 'transparent',
    border: '1px solid',
    padding: '10px 30px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '20px !important',
    },
    '&:focus-visible': {
      borderRadius: 50,
      outline: '2px dotted #666666',
      outlineOffset: 2,
    },
  },
}));

const Gallery = () => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.up('xs') && theme.breakpoints.down('sm'));
  const classes = useStyles({ mobile });
  // eslint-disable-next-line no-unused-vars
  const [tabs, setTabs] = useState(2);
  useEffect(() => {
    document.title = 'MultiplAI - Gallery';
    if (process.env.REACT_APP_ENV === 'prod') {
      document.getElementById('2022yeargalleytab').addEventListener('click', function () {
        // eslint-disable-next-line no-undef
        gtag('event', 'Gallery2022_TabButtonclick', {
          event_category: 'Button Click',
          event_label: 'Gallery 2022 Tab',
          button_type: 'CTA',
        });
      });
      document.getElementById('2023yeargalleytab').addEventListener('click', function () {
        // eslint-disable-next-line no-undef
        gtag('event', 'Gallery2023_TabButtonclick', {
          event_category: 'Button Click',
          event_label: 'Gallery 2023 Tab',
          button_type: 'CTA',
        });
      });
    }
  }, []);
  return (
    <>
      <Box sx={{ marginTop: { md: '64px', xs: '55px' }, width: '100%' }}>
        <img src={mobile ? galleryMobileBanner : galleryDesktopBanner} alt='gallery banner' width='100%' />
      </Box>
      <Grid
        container
        id='gallery'
        alignItems='center'
        justifyContent='center'
        style={{
          background: 'linear-gradient(186deg, #522c3d 0%, #0f0017 48%, #0a0807 100%)',
          width: '100%',
          paddingLeft: '6%',
          paddingRight: '6%',
          // paddingBottom: mobile ? '30px' : '110px',
          // paddingTop: mobile ? '100px' : '150px',
          marginTop: '-5px',
        }}
      >
        <Grid className={classes.container}>
          <Grid container style={{ marginBottom: 40, width: '100%' }}>
            <button
              aria-label={tabs === 0 ? '2022 images and videos selected' : '2022 images and videos'}
              className={tabs === 0 ? classes.activeTabStyles : classes.tabStyles}
              style={{ marginRight: 20 }}
              onClick={() => setTabs(0)}
              id='2022yeargalleytab'
            >
              2022
            </button>
            <button
              aria-label={tabs === 1 ? '2023 images and videos selected' : '2023 images and videos'}
              className={tabs === 1 ? classes.activeTabStyles : classes.tabStyles}
              style={{ marginRight: 20 }}
              onClick={() => setTabs(1)}
              id='2023yeargalleytab'
            >
              2023
            </button>
            <button
              aria-label={tabs === 2 ? '2024 images and videos selected' : '2024 images and videos'}
              className={tabs === 2 ? classes.activeTabStyles : classes.tabStyles}
              style={{ marginRight: 20 }}
              onClick={() => setTabs(2)}
              id='2024yeargalleytab'
            >
              2024
            </button>
          </Grid>
          {tabs === 0 ? (
            <GalleryTabs galleryImages={galleryImages} galleryVideos={MultiplaiVideos} event_year={2022} />
          ) : tabs === 1 ? (
            <GalleryTabs galleryImages={galleryImages2023} galleryVideos={MultiplaiVideos2023} event_year={2023} />
          ) : (
            <GalleryTabs galleryImages={galleryImages2024} galleryVideos={MultiplaiVideos2024} event_year={2024} />
          )}
        </Grid>
        {/* <Grid className={classes.container}>
                    <GalleryTabs />
                </Grid> */}
      </Grid>
    </>
  );
};
export default Gallery;
