const videos = {
  videos: [
    {
      url: 'https://gfc.target.com/multiplai/2023/Thumbnail_video.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/Multipli_reel.mp4',
      title: 'Highlights from Target Multiplai 2022',
      captions: 'https://gfc.target.com/multiplai/2023/sizzle_reel.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_BRUCE.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Bruce_Starns.mp4',
      title: 'Welcome note',
      name: 'Bruce Starnes | Senior Vice President - Target & President of Target in India',
      captions: 'https://gfc.target.com/multiplai/Bruce_Starnes.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_BRETT.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Brett_Craig.mp4',
      title: 'Role of technology in enhancing retail',
      name: 'Brett Craig | Executive Vice President and Chief Information Officer, Target',
      captions: 'https://gfc.target.com/multiplai/Brett_Craig.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_BRAD.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Brad_Thompson.mp4',
      title: 'You’ve created a brilliant algorithm….So, let’s make sure it gets used',
      name: 'Brad Thompson | Senior Vice President - Data Sciences, Target',
      captions: 'https://gfc.target.com/multiplai/Brad_Thompson.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_SHAUVIK.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Sauvik_Banerjjee.mp4',
      title: 'AI for India – Impacting millions at scale',
      name: 'Sauvik Banerjjee | Founding CTO - Tata Neu & Tata Digital',
      captions: 'https://gfc.target.com/multiplai/Sauvik_Banerjjee.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_ANAND.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Anand_Chandrasekaran-.mp4',
      title: 'The journey to generalizable intelligence',
      name: 'Anand Chandrasekaran | Founder and CTO - Mad Street Den',
      captions: 'https://gfc.target.com/multiplai/Anand_Chandrasekaran.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_RAMYA.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Ramya&Vipin.mp4',
      title: 'Next frontiers of AI in global supply chain',
      name: 'Ramya Raghunathan | Vice President - Data Sciences, Target and Vipin Jawaria | Vice President - Global Supply Chain & Logistics, Target in India',
      captions: 'https://gfc.target.com/multiplai/Ramya_and_Vipin.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_SHEELA.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Sheela_Siddappa.mp4',
      title: 'AI for operational efficiency',
      name: 'Dr. Sheela Siddappa | Principal Data Scientist, Kyndryl',
      captions: 'https://gfc.target.com/multiplai/Sheela_Siddappa.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_NIDHI.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Nidhi_Mathur.mp4',
      title: 'Ethics in AI',
      name: 'Nidhi Mathur | Head, Axilor Labs',
      captions: 'https://gfc.target.com/multiplai/Nidhi_Mathur.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_ERROL.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Errol_Koolmeister.mp4',
      title: 'Capabilities for scaling AI architecture',
      name: 'Errol Koolmeister | AI & Data Advisor, The AI Framework',
      captions: 'https://gfc.target.com/multiplai/Errol_Koolmeister.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_SHREELATHA.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/DrSeelata_Workshop.mp4',
      title: 'Pricing experiments for retail',
      name: 'Dr. Sreelata Jonnalagedda | Professor - Marketing, IIM Bangalore',
      captions: 'https://gfc.target.com/multiplai/Sreelata.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_SWATI.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Swati_jain.mp4',
      title: 'Artificial intelligence for real impact',
      name: 'Dr. Swati Jain | Vice President - Decision Analytics, EXL Service',
      captions: 'https://gfc.target.com/multiplai/Swati_Jain.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_SARAH.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/MelissaandSarah.mp4',
      title: 'When human and artificial intelligence collaborate',
      name: 'Melissa Ludack | Vice President - Data Sciences, Target and Sarah Travis | President - Roundel, Target',
      captions: 'https://gfc.target.com/multiplai/Melissa_and_Sarah.vtt',
    },
    {
      url: 'https://gfc.target.com/multiplai/VIDEO_THUMBNAIL_SHAILESH.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/Shailesh.mp4',
      title: 'Co-occurrence analysis - An unsupervised approach',
      name: 'Dr. Shailesh Kumar | Chief Data Scientist, Reliance Jio',
      captions: 'https://gfc.target.com/multiplai/Shailesh.vtt',
    },
  ],
};

export default videos;
