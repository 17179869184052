const videos = {
  videos: [
    {
      url: 'https://gfc.target.com/multiplai/2023/MultiplAi_Sizzle_tumbnail.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/MultiplAi_Sizzle.mp4',
      title: 'Highlights from Target Multiplai 2023',
      captions: 'https://gfc.target.com/multiplai/2023/Multiplai_Sizzle_reel.vtt',
      name: '',
    },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/Anusha.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Anusha Rammohan.mp4',
      title: 'At the edge of a new frontier with generative AI',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Anusha_Rammohan.vtt',
      name: 'Anusha Rammohan | Senior Director - Media and Entertainment, Myelin Foundry',
    },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/brad-and-meridith.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Brad Thompson & Meredith Jordan.mp4',
      title: 'Solving for availability – the holy grail in retail',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Brad_Thompson_Meredith_Jordan.vtt',
      name: 'Brad Thompson | SVP - Data Sciences, Target and Meredith Jordan | Vice President - Engineering, Stores Portfolio, Target',
    },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/Bruce.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Bruce Starnes.mp4',
      title: 'Building future-ready AI at scale in retail',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Bruce_Starnes.vtt',
      name: 'Bruce Starnes | President, Target in India',
    },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/Jyothi.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Dr. Jyoti Joshi.mp4',
      title: 'AI at scale: Fostering empathy in retail',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Dr_Jyoti_Joshi.vtt',
      name: 'Dr. Jyoti Joshi | Researcher & Founder - Kroop AI',
    },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/Radhika.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Dr. Radhika Kulkarni.mp4',
      title: 'Innovative optimization -the key to success in AI',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Dr_Radhika_Kulkarni.vtt',
      name: 'Dr. Radhika Kulkarni | Advanced Analytics Professional',
    },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/Venkata.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Dr. Venkata Pingali.mp4',
      title: 'Feature engineering in production -learnings and evolution',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Dr_Venkata_Pingali.vtt',
      name: 'Dr. Venkata Pingali | Co-founder & CEO, Scribble Data',
    },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/Warren.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Dr. Warren B Powell.mp4',
      title: 'Revolutionizing decision-making with sequential decision analytics',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Dr_Warren_B_Powell.vtt',
      name: 'Dr. Warren B Powell | Co-founder & CIO, Optimal Dynamics Prof Emeritus - Princeton University',
    },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/Melissa&hari.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Melissa Ludack & Harikrishnan Aravindakshan.mp4',
      title: 'The science of personalization using AI',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Melissa_Ludack_Harikrishnan_Aravindakshan.vtt',
      name: 'Melissa Ludack | Vice President - Data Sciences, Target and Harikrishnan Aravindakshan | Vice President - Product Engineering, Target',
    },
    // {
    //   url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/mike.png',
    //   videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Mike Calvo.mp4',
    //   title: 'How Shipt is harnessing AI/ ML to build the future of delivery',
    //   captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Mike_Calvo.vtt',
    //   name: 'Mike Calvo | Chief Technology Officer Shipt',
    // },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/ramesh.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Ramesh Raskar.mp4',
      title: 'Making the ‘invisible’ visible: An uncharted path for AI and imaging in retail',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Ramesh_Raskar.vtt',
      name: 'Ramesh Raskar | Associate Professor, MIT Media Lab',
    },
    {
      url: 'https://gfc.target.com/multiplai/2023/gallery/thumbnails/rohit-and-abhijith.png',
      videoUrl: 'https://gfc.target.com/multiplai/2023/gallery/videos/Rohit Punnoose & Abhijit Mangalgiri.mp4',
      title: 'The power of AI on platforms: Driving markdown optimization in retail',
      captions: 'https://gfc.target.com/multiplai/2023/gallery/vtt/Rohit_Punnoose_Abhijit_Mangalgiri.vtt',
      name: 'Rohit Punnoose | Senior Director - Data Sciences, Target and Abhijit Mangalgiri | Senior Director - Product Engineering, Target',
    },
  ],
};

export default videos;
