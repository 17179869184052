import { Grid, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useUnsubscribeUserMutation } from '../../api/unsubscribe';
import { unsubscribed } from '../../assets/Images/Images';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Dialog, DialogContent, Button, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const Unsubscribe = () => {
  const [open, setOpen] = useState(true);
  const [searchParams] = useSearchParams();
  const desktop = useMediaQuery('(min-width:1440px)');
  const userEmailId = searchParams.get('email');
  const eventId = useSelector((state) => state.tenant.eventId);
  const [sure, setSure] = useState(false);
  const [unsubscribeUser, { data: unsubscribeUserResponse, error: unsubscribeUserResError }] = useUnsubscribeUserMutation();
  const [unsubscribeMsg, setUnsubscribeMsg] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (sure === 'yes') {
      try {
        unsubscribeUser({
          eventId,
          userEmailId,
        });
      } catch (error) {
        console.log(' in error ', error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sure]);

  useEffect(() => {
    if (unsubscribeUserResponse?.status === 'success') {
      setUnsubscribeMsg('first time');
    }
    if (unsubscribeUserResError?.data.message === 'Invalid email id or participant details not found') {
      setUnsubscribeMsg('second time');
    }
    if (unsubscribeUserResError?.data.message === 'Invalid email id') {
      setUnsubscribeMsg('invalid');
    }
  }, [unsubscribeUserResError, unsubscribeUserResponse, sure]);

  const handleCloseNo = () => {
    setSure('no');
    setOpen(false);
    navigate('/');
  };
  const handleCloseYes = () => {
    setSure('yes');
    setOpen(false);
  };
  const handleClose = () => {
    if (sure === 'yes' || sure === 'no') {
      setOpen(false);
    } else {
      return;
    }
  };
  return (
    <Box sx={{ minHeight: '91vh' }}>
      <Dialog
        open={open}
        sx={{
          width: '30%',
          margin: '0 auto',
          '& .MuiPaper-root': {
            borderRadius: '25px',
            width: '100%',
            boxShadow: 'none',
          },
          '& .MuiBackdrop-root ': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        }}
        onClose={handleClose}
        role='dialog'
        aria-modal='true'
        aria-label={`are you sure to unsubscribe`}
      >
        <DialogContent
          style={{
            padding: '40px',
            borderBottom: 'none',
            overflow: 'unset',
            paddingBottom: '10px',
            borderRadius: '25px',
            textAlign: 'center',
          }}
        >
          Once you unsubscribe, you will stop receiving updates about Target Multiplai in your Inbox and will not be able to access the event. Would you like to
          continue?
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>
          <Button
            sx={{ textTransform: 'none', backgroundColor: '#cc0000', color: '#fff', '&:hover ': { backgroundColor: '#cc0000', color: '#fff' } }}
            onClick={handleCloseYes}
          >
            Yes, Unsubscribe
          </Button>
          <Button
            sx={{
              minWidth: '128px',
              textTransform: 'none',
              backgroundColor: '#cc0000',
              color: '#fff',
              '&:hover ': { backgroundColor: '#cc0000', color: '#fff' },
            }}
            onClick={handleCloseNo}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {sure && (
        <Grid
          container
          sx={{
            mt: '5%',
            minHeight: '86vh',
            padding: desktop ? '16px 78px 13px !important' : '16px 28px 13px !important',
          }}
        >
          {/* {unsubscribeUserResponse && ( */}
          <Grid md={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center' }}>
            {unsubscribeMsg && (
              <>
                <div>
                  <img src={unsubscribed} alt='unsubscribed' width='75px' height='75px' style={{ marginBottom: '15px' }} />
                </div>
                {unsubscribeMsg === 'first time' ? (
                  <>
                    <Typography sx={{ fontSize: '34px', color: '#FFF' }}>We have removed your email address</Typography>
                    <Typography sx={{ fontSize: '34px', color: '#FFF' }}>from the Target Multiplai mailing list. </Typography>
                    <Typography sx={{ fontSize: '34px', fontWeight: 'bolder', color: '#FFF' }}>We’re sorry to see you go and </Typography>
                    <Typography sx={{ fontSize: '34px', fontWeight: 'bolder', color: '#FFF' }}>hope to see you at a future edition!</Typography>
                  </>
                ) : (
                  <>
                    <Typography sx={{ fontSize: '34px', color: '#FFF' }}>Your email address has already been </Typography>
                    <Typography sx={{ fontSize: '34px', fontWeight: 'bolder', color: '#FFF' }}>removed from the Target Multiplai mailing list.</Typography>
                  </>
                )}
              </>
            )}
          </Grid>
          {/* )} */}
        </Grid>
      )}
    </Box>
  );
};

export default Unsubscribe;
