import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiConfig from '../apiConfig';

const { api } = apiConfig;

export const UnsubscribeApi = createApi({
  reducerPath: 'UnsubscribeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: api.host,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().session.authToken;
      if (token) {
        headers.set('authorization', token);
      }
      return headers;
    },
  }),
  tagTypes: ['Unsubscribe'],
  endpoints: (builder) => ({
    unsubscribeUser: builder.mutation({
      query: ({ eventId, userEmailId }) => ({
        url: `content/events/${eventId}/participant/auth/unsubscribe`,
        method: 'POST',
        params: {
          key: api.key,
        },
        body: {
          email: userEmailId,
          delete: true,
        },
      }),
      providesTags: ['Unsubscribe'],
    }),
  }),
});

export const { useUnsubscribeUserMutation } = UnsubscribeApi;
