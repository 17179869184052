const videos = {
  videos: [
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/MultiplaiSizzle_Thumbnail2.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/MultiplaiSizzle24-V8_FINAL_APPROVED.mp4',
      title: 'Highlights of Multiplai 2024',
      name: '',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Andrea Zimmerman.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/AndreaZimmerman.mp4',
      title: 'Welcome to Target Multiplai',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/AndreaZimmerman.vtt',
      name: 'Andrea Zimmerman, President, Target in India',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Arvind Jayaprakash.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Arvind Jayaprakash.mp4',
      title: 'Engineering for machine learning: What a decade of experience has taught us',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Arvind_Jayaprakash.vtt',
      name: 'Arvind Jayaprakash, SVP – Technology, Glance',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Brad Thompson & Nidhi Sen.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/BradThompson & Nidhi.mp4',
      title: 'Moving Beyond PoCs – Scaling GenAI to Impact',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/BradThompson__Nidhi.vtt',
      name: 'Brad Thompson, SVP - Technology, Target |  Nidhi Sen, Sr. Director - Technology, Target',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Dr Manish Gupta.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Dr Manish Gupta.mp4',
      title: 'The Transformative Power of AI and Open Challenges',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Dr_Manish_Gupta.vtt',
      name: 'Dr Manish Gupta, Director - Research, Google',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Melissa Ludack & Mudit Ranjan Pati.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Melissa & Mudit.mp4',
      title: 'Delighting Guests with Personalization using Reinforcement Learning',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Melissa__Mudit.vtt',
      name: 'Melissa Ludack, VP - Data Sciences, Target & Mudit Ranjan Pati, Sr Director - Data Sciences, Target in India',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Prasad Deshpande.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Prasad Deshpande.mp4',
      title: 'From Data Silos to AI Success through Scalable Pipelines',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Prasad_Deshpande.vtt',
      name: 'Prasad Deshpande, Senior Staff Software Engineer, Databricks',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Raghavan Subramanian.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Raghavan.mp4',
      title: 'Advantage Tennis through AI',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Raghavan.vtt',
      name: 'Raghavan Subramanian, Associate Vice President and Head, Infosys Tennis Platform',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Ramya Raghunathan & Sharad Limaye.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Ramya Raghunathan & Sharad Limaye.mp4',
      title: 'The Science of Forecasting and Inventory Management',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Ramya_Raghunathan__Sharad_Limaye.vtt',
      name: 'Ramya Raghunathan, VP - Data Sciences, Target & Sharad Limaye, Sr Director - Data Sciences, Target in India',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Satnam Singh.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Satnam Singh.mp4',
      title: 'AI-Powered Deception for Identity Protection',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Satnam_Singh.vtt',
      name: 'Satnam Singh, Chief Data Scientist, Acalvio Technologies',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Vaibhav Khandelwal.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Vaibhav.mp4',
      title: 'AI to Enhance Last-Mile Delivery Experience',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Vaibhav.vtt',
      name: 'Vaibhav Khandelwal, Co-founder and CTO, Shadowfax',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Abhishek Mishra.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Abhishek Mishra.mp4',
      title: 'Workshop: ML Model Operations and Observability',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Abhishek_Mishra (1).vtt',
      name: 'Abhishek Mishra, Senior Manager - Data, Snowflake',
    },
    {
      url: 'https://gfc.target.com/multiplai/2024/gallery_images/Aashimi & Ritesh_High-performance computing with Spark_WorkShop.jpg',
      videoUrl: 'https://gfc.target.com/multiplai/2024/gallery_videos/Aashimi & Ritesh_High-performance computing with Spark_WorkShop.mp4',
      title: 'Workshop: High Performance Computing with Spark',
      captions: 'https://gfc.target.com/multiplai/2024/vtt/Aashimi__Ritesh_High-performance_computing_with_Spark_WorkShop.vtt',
      name: 'Aashimi Bhatia, Principal Data Scientist, Target in India & Ritesh Rajani, Principal AI Engineer, Target in India',
    },
  ],
};

export default videos;
