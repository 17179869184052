import { Box, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import styles from './pastSpeakers.module.css';
import speakers2022 from './SpeakersData/Speakers2022.json';
import speakers2023 from './SpeakersData/Speakers2023.json';
import RenderSpeakers from './RenderSpeakers';
import { psDesktopBanner, psMobileBanner } from '../../assets/Images/Images';

const PastSpeakers = () => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [selectedYear, setSelectedYear] = useState(1);
  const years = [2022, 2023];
  const speakersDataMap = {
    2022: speakers2022,
    2023: speakers2023,
  };
  useEffect(() => {
    document.title = 'MultiplAI - Past speakers';
    if (process.env.REACT_APP_ENV === 'prod') {
      document.getElementById('2022tab').addEventListener('click', function () {
        // eslint-disable-next-line no-undef
        gtag('event', 'Speakers2022_Buttonclick', {
          event_category: 'Button Click',
          event_label: 'Speakers 2022 Button Click',
          button_type: 'CTA',
        });
      });
      document.getElementById('2023tab').addEventListener('click', function () {
        // eslint-disable-next-line no-undef
        gtag('event', 'Speakers2023_Buttonclick', {
          event_category: 'Button Click',
          event_label: 'Speakers 2023 Button Click',
          button_type: 'CTA',
        });
      });
    }
  }, []);
  return (
    <>
      <Grid container className={styles['speaker-container']} sx={{ paddingTop: { md: '60px', xs: '55px' } }}>
        <Box className={styles['headerbanner']} sx={{ paddingBottom: { md: '40px', xs: '20px' }, width: '100%' }}>
          <img src={mobile ? psMobileBanner : psDesktopBanner} alt='past speakers' width='100%' />
        </Box>
        <Grid>
          <Grid container className={styles['year-tabs']} sx={{ paddingLeft: { md: '120px', xs: '20px' } }}>
            {years.map((year, yIndex) => (
              <>
                <button
                  id={`${years[yIndex]}tab`}
                  aria-label={
                    selectedYear === yIndex
                      ? `${years[selectedYear]} past speakers selected`
                      : `${years.filter((year) => year !== years[selectedYear])} past speakers`
                  }
                  className={selectedYear === yIndex ? styles['activeImagesStyles'] : styles['imagesStyles']}
                  onClick={() => setSelectedYear(yIndex)}
                >
                  {year}
                </button>
              </>
            ))}
          </Grid>
          <RenderSpeakers speakersArray={speakersDataMap[years[selectedYear]]} />
        </Grid>
      </Grid>
    </>
  );
};

export default PastSpeakers;
