import React from 'react';
import './App.css';
import { useRoutes, useLocation, useSearchParams, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import utilFunction from './Components/Shared/utilFunction';
import GlobalStyles from '@mui/material/GlobalStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import Header from './Layout/Header';
// import Footer from './Layout/Footer';

import { PageLoader, StencilTenant, useTenantContext, useGetPageContentQuery } from 'enterprise-stencil';
import { store } from './store';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
// import { PersistGate } from 'redux-persist/integration/react';
import { Box } from '@mui/material';
import { setEventId } from './api/tenant';
import { useGetActiveEventsQuery } from './api/tenant';
import { useDispatch } from 'react-redux';
// import Registration from './Components/Registration/Registration';
// import SignUpDialog from './Components/Registration/SignUpDialog';
// import { setExistingUserData } from './api/validateUser';
// import usePageTracking from './Utils/usePageTracking';
import { deepmerge } from '@mui/utils';
import ScrollToTop from './Utils/ScrollToTop';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Gallery from './Pages/Gallery/Gallery';
import Unsubscribe from './Components/Unsubscribe/Unsubscribe';
import PastSpeakers from './Pages/PastSpeakers/PastSpeakers';
// import InVenueBooking from './Components/InVenueBooking/InVenueBooking';
// import AdminPanel from './Components/InVenueBooking/AdminPanel/AdminPanel';
import AgendaCustom from './Components/AgendaCustom/AgendaCustom';
// import InVenueBooking from './Components/InVenueBooking/InVenueBooking';
// import PrintCard from './Components/InVenueBooking/PrintCard';

const customComponents = {
  customAgenda: AgendaCustom,
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Container = (props) => {
  const location = useLocation();
  const { tenantId } = useTenantContext();
  const dispatch = useDispatch();
  const [pageData, setPageData] = React.useState(null);
  let query = useQuery();
  const preview = query.get('preview');
  const { data: fetchedData, isLoading } = useGetPageContentQuery(
    {
      tenantId,
      slug: location.pathname.slice(1) ? `/${location.pathname.slice(1)}` : '',
      previewToken: preview,
    },
    { skip: !tenantId }
  );

  React.useEffect(() => {
    setPageData(fetchedData);
  }, [fetchedData]);

  const { data: eventsData, error: eventsError } = useGetActiveEventsQuery({ site_id: tenantId }, { skip: !tenantId });
  // const [openRegDialog, setOpenRegDialog] = React.useState(false);

  React.useEffect(() => {
    if (eventsData && eventsData.length > 0) {
      dispatch(setEventId({ eventId: eventsData[0]._id }));
      localStorage.setItem('eventId', JSON.stringify(eventsData[0]._id));
    }

    // dispatch(
    //   setExistingUserData({
    //     member: {},
    //     meta: {},
    //     status: [],
    //   })
    // );
    // setTimeout(() => {
    //   document.getElementsByClassName('MuiButton-contained') &&
    //     document.getElementsByClassName('MuiButton-contained').length > 0 &&
    //     document.getElementsByClassName('MuiButton-contained')[0].addEventListener('click', function () {
    //       setOpenRegDialog(true);
    //     });
    // }, 2000);
  }, [dispatch, eventsData, eventsError]);

  // handling the google AX

  // usePageTracking();

  return (
    <>
      <Helmet>
        <title>{fetchedData ? fetchedData.name : 'Target Multiplai AI Conference'}</title>
        <meta name='description' content={fetchedData ? fetchedData.seo?.description : 'desc'} />
        <meta property='og:title' content={fetchedData ? fetchedData.seo?.title : ''} />
        <meta property='og:image' content={fetchedData ? fetchedData.seo?.image : ''} />
      </Helmet>
      <>
        <h1 className='visibility-hidden' aria-hidden='true'>
          {fetchedData && fetchedData.seo && fetchedData.seo.title ? fetchedData.seo.title : fetchedData && fetchedData.name ? fetchedData.name : ''}
        </h1>
        {isLoading ? (
          <Box sx={{ minHeight: '65vh', width: '100%' }} />
        ) : (
          <>
            <PageLoader layout={pageData && pageData.layout} isLoading={false} customComponents={customComponents} />
            {/* <SignUpDialog isBanner={true} openSignUp={openRegDialog} setopenSignUp={setOpenRegDialog} /> */}
          </>
        )}
        {/* {eventsError && <p>There are no events registered yet</p>}
        <Typography variant='body1' sx={{ color: '#fff', mt: '10%', minHeight: '70vh' }}>
          We are not able to find the page for the given URL.
        </Typography> */}
      </>
    </>
  );
};

const Main = () => {
  const [searchParams] = useSearchParams();
  const isMobileApp = searchParams.get('ismobileapp');
  // const dispatch = useDispatch();
  React.useEffect(() => {
    if (isMobileApp === 'true') {
      // dispatch(showInVenue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileApp]);

  return (
    <>
      <Routes />
    </>
  );
};

const Routes = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { preloadedData, preloadedArticleData, isLoading } = props;
  let routes = useRoutes([
    {
      path: '*',
      element: <Container isLoading={isLoading} preloadedData={preloadedData} />,
    },
    // {
    //   path: '/registeronspot',
    //   element: <Registration />,
    // },
    // {
    //   path: '/spot',
    //   element: <InVenueBooking />,
    // },
    {
      path: '/register',
      element: <Navigate to='/' replace />,
    },
    {
      path: '/pastspeakers',
      element: <PastSpeakers />,
    },
    {
      path: '/gallery',
      element: <Gallery />,
    },

    {
      path: '/unsubscribe',
      element: <Unsubscribe />,
    },
    // {
    //   path: '/mentorships',
    //   element: <Mentorships></Mentorships>,
    // },
    // {
    //   path: '/spot',
    //   element: <InVenueBooking />,
    // },

    // {
    //   path: '/printTest',
    //   element: <PrintCard />,
    // },
    // {
    //   path: '/adminpanel',
    //   element: <AdminPanel />,
    // },
    {
      path: '/agendamobile',
      element: <AgendaCustom />,
    },
  ]);
  return routes;
};

let theme = createTheme();

const styles = {
  background: {
    default: '#fafafa',
    paper: '#ffffff',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
};

const fontSettings = {
  typography: {
    fontFamily: 'HelveticaForTarget, Helvetica, Arial, sans-serif',
    fontWeightBold: 600,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h3: {
      // fontWeight: '400 !important',
    },
    h4: {
      // fontWeight: '400 !important',
    },
  },
  components: {
    MuiCssBaseline: {},
  },
};

let merged = Object.assign({}, fontSettings, styles);
theme = createTheme(deepmerge(merged));
function App(props) {
  // eslint-disable-next-line no-unused-vars
  const tablet = useMediaQuery(theme.breakpoints.up('sm'));
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const variantStyles = {
    '& .article-body': {
      fontSize: 18,
      '& h3 ': {
        fontWeight: 500,
        [theme.breakpoints.up('lg')]: {
          fontSize: utilFunction('h4', 'XL', 'Desktop').sizeVal,
          lineHeight: utilFunction('h4', 'XL', 'Desktop').sizeVal + 2 + 'px',
        },
        [theme.breakpoints.down('lg')]: {
          fontSize: utilFunction('h4', 'XL', 'Tablet').sizeVal,
          lineHeight: utilFunction('h4', 'XL', 'Tablet').sizeVal + 2 + 'px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: utilFunction('h4', 'XL', 'Mobile').sizeVal,
          lineHeight: utilFunction('h4', 'XL', 'Mobile').sizeVal + 2 + 'px',
        },
      },
    },
    // h3 & h4 font sizes
    '& h2.h3-fontxl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'XL', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'XL', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'XL', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'XL', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'XL', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'XL', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontxl.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'XL', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XL', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'XL', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XL', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'XL', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XL', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h2.h3-fontl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontl.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'L', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'L', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'L', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'L', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'L', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'L', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h2.h3-fontm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontm.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'M', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'M', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'M', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'M', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'M', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'M', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h2.h3-fonts': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fonts.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'S', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'S', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'S', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'S', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'S', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'S', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h2.h3-fontxs': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h3', 'XS', 'Desktop').sizeVal,
        lineHeight: utilFunction('h3', 'XS', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h3', 'XS', 'Tablet').sizeVal,
        lineHeight: utilFunction('h3', 'XS', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h3', 'XS', 'Mobile').sizeVal,
        lineHeight: utilFunction('h3', 'XS', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h2.h3-fontxs.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h3', 'XS', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XS', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h3', 'XS', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XS', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h3', 'XS', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h3', 'XS', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fontxl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'XL', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'XL', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'XL', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'XL', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'XL', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'XL', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontxl.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'XL', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XL', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'XL', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XL', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'XL', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XL', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fontl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontl.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'L', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'L', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'L', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'L', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'L', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'L', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fontm, p.h4-fontm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontm.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'M', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'M', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'M', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'M', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'M', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'M', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fonts': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fonts.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'S', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'S', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'S', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'S', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'S', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'S', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& h3.h4-fontxs': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('h4', 'XS', 'Desktop').sizeVal,
        lineHeight: utilFunction('h4', 'XS', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('h4', 'XS', 'Tablet').sizeVal,
        lineHeight: utilFunction('h4', 'XS', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('h4', 'XS', 'Mobile').sizeVal,
        lineHeight: utilFunction('h4', 'XS', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& h3.h4-fontxs.responsive': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('h4', 'XS', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XS', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('h4', 'XS', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XS', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('h4', 'XS', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('h4', 'XS', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    // button text
    '& button.button-textxl': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'XL', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XL', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XL', 'Mobile').sizeVal,
      },
    },
    '& button.button-textl': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'L', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'L', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'L', 'Mobile').sizeVal,
      },
    },
    '& button.button-textm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('button', 'M', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('button', 'M', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'M', 'Mobile').sizeVal,
      },
    },
    '& button.button-texts': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'S', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'S', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'S', 'Mobile').sizeVal,
      },
    },
    '& button.button-textxs': {
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('button', 'XS', 'Desktop').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XS', 'Tablet').sizeVal,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('button', 'XS', 'Mobile').sizeVal,
      },
    },

    //button specific tab component
    '& .MuiTabs-flexContainer button.Mui-selected': {
      fontWeight: 400,
      [theme.breakpoints.up('md')]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    '& .MuiTabs-flexContainer button': {
      fontWeight: 400,
      [theme.breakpoints.up('md')]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    '& .MuiTabs-flexContainer a': {
      [theme.breakpoints.up('md')]: {
        fontSize: '24px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px !important',
      },
    },
    '& .MuiTabs-root': {
      [theme.breakpoints.up('md')]: {
        minHeight: '60px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '58px !important',
      },
    },

    //button sizes
    '& button.button-size': {},

    // body1 fontsizes
    '& .body1-fontxl p,.body1-fontxl li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'XL', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'XL', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'XL', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'XL', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'XL', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'XL', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fontxl.responsive p,.body1-fontxl.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'XL', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XL', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'XL', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XL', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'XL', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XL', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& .body1-fontl p, .body1-fontl li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fontl.responsive p,.body1-fontl.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'L', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'L', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'L', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'L', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'L', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'L', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& .body1-fontm p,.body1-fontm li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fontm.responsive p,.body1-fontm.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'M', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'M', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'M', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'M', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'M', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'M', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& .body1-fonts p,.body1-fonts li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fonts.responsive p,.body1-fonts.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'S', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'S', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'S', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'S', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'S', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'S', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },
    '& .body1-fontxs p,.body1-fontxs li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'XS', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'XS', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'XS', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'XS', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'XS', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'XS', 'Mobile').sizeVal + 2 + 'px',
      },
    },
    '& .body1-fontxs.responsive p,.body1-fontxs.responsive li': {
      [theme.breakpoints.up('lg')]: {
        fontSize: `${utilFunction('body1', 'XS', 'Desktop', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XS', 'Desktop', true).sizeVal + 0.11142858 + 'vw',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: `${utilFunction('body1', 'XS', 'Tablet', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XS', 'Tablet', true).sizeVal + 0.260714285 + 'vw',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: `${utilFunction('body1', 'XS', 'Mobile', true).sizeVal}vw`,
        lineHeight: utilFunction('body1', 'XS', 'Mobile', true).sizeVal + 0.472222221 + 'vw',
      },
    },

    // anchor tags
    '& a': {
      color: 'black',
      // fontWeight: "bold",
      // fontSize: "20px",
      // textDecorationColor: "black !important",
    },
    '& .elevate-hero-banner a': {
      color: '#fff',
      textDecoration: 'underline',
      textDecorationColor: '#fff !important',
    },
    '& a:focus-visible': {
      outline: '1px dotted',
      color: 'black',
    },

    '& a.header-hover:hover': {
      color: '#404040 !important',
    },

    '& button:focus-visible': {
      outline: '1px dotted #000',
    },

    '& .body1-general a': {
      fontSize: 'inherit',
    },

    '& .linkButtonWhite a': {
      color: 'white !important',
      fontWeight: 'bold',
      fontSize: '20px',
      textDecorationColor: 'white !important',
    },

    //svg icons
    '& .button-textm span svg': {
      width: '2.5rem',
      height: '2.5rem',
    },

    // numbered lists

    '& .body1-general ol': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      marginLeft: '35px',
    },

    '& .body1-general ol li': {
      counterIncrement: 'step-counter',
      marginBottom: '30px',
      position: 'relative',
    },

    '& .body1-general ol li::before': {
      content: 'counter(step-counter)',
      marginRight: '10px',
      backgroundColor: '#000',
      color: 'white',
      padding: '4px 10px',
      borderRadius: '50%',
      position: 'absolute',
      marginLeft: '-40px',
      marginTop: '-3px',
    },

    '& .body1-general ul': {
      paddingLeft: '20px',
    },

    // svg arrows
    '& .slider-control-centerleft': {
      [theme.breakpoints.up('md')]: {
        left: '8% !important',
        display: 'block',
      },
      button: {
        color: 'black',
      },
      display: 'none',
    },

    '& .slider-control-centerright': {
      [theme.breakpoints.up('md')]: {
        right: '8% !important',
        display: 'block',
      },
      button: {
        color: 'black',
      },
      display: 'none',
    },

    '& .article-body p': {
      fontSize: 18,
      fontWeight: 'normal',
      [theme.breakpoints.up('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& .article-body .MuiAccordionSummary-content h3': {
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '26px',
      },
    },

    '& .article-body .MuiAccordion-root:before': {
      backgroundColor: 'transparent',
    },
    // accordion article
    '& .article-body .MuiAccordionSummary-content p.accordion-list': {
      marginRight: '10px',
      backgroundColor: '#000',
      color: 'white',
      padding: '5px 10px',
      borderRadius: '50%',
      //position: "absolute",
      marginLeft: '-20px',
    },

    '& .article-body .MuiAccordionSummary-content svg': {
      fontSize: '4.5rem',
    },

    '& .article-body .MuiAccordionSummary-root': {
      paddingTop: 25,
      paddingBottom: 25,
    },

    '& .article-body .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'flex-start !important',
    },

    '& .article-body .MuiAccordionDetails-root': {
      paddingLeft: 36,
    },

    '& .article-body .MuiAccordion-root:last-of-type': {
      borderBottom: 0,
    },

    '& .article-body .MuiAccordionDetails-root .body1-general p': {
      marginTop: '0',
      marginBottom: '0',
    },

    '& .slider-container .slide .MuiGrid-item': {
      opacity: '0 !important',
    },
    '& .slider-container .slide-visible': {
      opacity: '1 !important',
    },
    '& .slider-container .slide-visible .MuiGrid-item': {
      opacity: '1 !important',
    },
    '& .circle-carousel .slide-current .Overlay': {
      opacity: 0,
    },

    '& .circle-carousel .circle-carousel-content-container ': {
      width: desktop ? '60%' : tablet ? '70%' : '90%',
    },
    '& .circle-carousel .circle-carousel-content-container .circle-carousel-content': {
      opacity: '0 !important',
    },
    '& .circle-carousel .slide-current .circle-carousel-content': {
      opacity: '1 !important',
    },
    '& .circle-carousel .circle-carousel-content-container .circle-carousel-content-image': {
      opacity: '0 !important',
    },
    '& .circle-carousel .slide-current .circle-carousel-content-image ': {
      opacity: '1 !important',
    },
    '& .circle-carousel .slider-container .slider-control-bottomcenter .pagingDotsCustom': {
      [theme.breakpoints.up('lg')]: {
        svg: {
          width: '13px !important',
          height: '13px !important',
        },
      },
      [theme.breakpoints.down('lg')]: {
        svg: {
          width: '13px !important',
          height: '13px !important',
        },
      },
      [theme.breakpoints.down('sm')]: {
        svg: {
          width: '12px !important',
          height: '12px !important',
        },
      },
    },

    //carousel
    '& .slider-container .slider-control-bottomcenter .pagingDots': {
      // top: "0px !important",
      [theme.breakpoints.up('sm')]: {
        svg: {
          width: '13px',
          height: '13px',
          circle: {
            cx: 6,
            cy: 6,
            r: 6,
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        top: '20px !important',
        svg: {
          width: '13px',
          height: '13px',
          circle: {
            cx: 6,
            cy: 6,
            r: 6,
          },
        },
      },
    },
    '& .slider-container .slider-list': {
      [theme.breakpoints.up('md')]: {
        marginBottom: '20px',
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '30px',
      },
    },
    '& .stats-li li': {
      listStyle: 'none',
    },
    '& .stats-li ul': {
      paddingLeft: 0,
    },
    '& .visibility-hidden': {
      position: 'absolute',
      clip: 'rect(1px, 1px, 1px, 1px)',
      clipPath: 'inset(0px 0px 99.9% 99.9%)',
      overflow: 'hidden',
      height: 1,
      width: 1,
      padding: 0,
      border: 0,
    },
    '& a.body1-fonts': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'S', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'S', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& a.body1-fontm': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'M', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& a.body1-fontlarge': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Desktop').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Tablet').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'L', 'Mobile').sizeVal,
        lineHeight: '30px',
      },
    },
    '& a.body1-fontsmall': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Desktop').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'S', 'Tablet').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'S', 'Mobile').sizeVal,
        lineHeight: '30px',
      },
    },

    '& a.body1-fontmedium': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Desktop').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'M', 'Tablet').sizeVal,
        lineHeight: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'M', 'Mobile').sizeVal,
        lineHeight: '30px',
      },
    },

    '& a.body1-fontl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Desktop').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: utilFunction('body1', 'L', 'Tablet').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: utilFunction('body1', 'L', 'Mobile').sizeVal,
        lineHeight: utilFunction('body1', 'L', 'Mobile').sizeVal + 2 + 'px',
      },
    },

    '& a.body1-fonts.header': {
      [theme.breakpoints.between(900, 1024)]: {
        fontSize: '1.3vw',
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.between(1024, 1200)]: {
        fontSize: '1.65vw',
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
    },

    '& a.body1-fonts.footer': {
      [theme.breakpoints.between(900, 1024)]: {
        fontSize: '1vw',
        lineHeight: utilFunction('body1', 'S', 'Desktop').sizeVal + 2 + 'px',
      },
      [theme.breakpoints.between(1024, 1200)]: {
        fontSize: '1.5vw',
        lineHeight: utilFunction('body1', 'S', 'Tablet').sizeVal + 2 + 'px',
      },
    },

    '& .herobannerheadline div.MuiBox-root div.MuiBox-root div.MuiBox-root div.MuiBox-root ': {
      [theme.breakpoints.only('xl')]: {
        width: '100%',
      },
      [theme.breakpoints.only('lg')]: {
        width: '100%',
      },
      [theme.breakpoints.only('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '& .herobannerheadline h2.h3-fontxl.responsive': {
      [theme.breakpoints.only('xl')]: {
        fontSize: '6vw !important',
        lineHeight: '8vw !important',
        textAlign: 'center',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '7vw !important',
        lineHeight: '7.5vw !important',
        textAlign: 'center',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '7.292vw !important',
        lineHeight: '8vw !important',
        textAlign: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '13vw !important',
        lineHeight: '14vw !important',
        textAlign: 'center',
      },
    },

    '& .herobannerheadline div.body1-general.body1-fontxl.responsive p': {
      [theme.breakpoints.only('xl')]: {
        fontSize: '2.5vw !important',
        lineHeight: '3vw !important',
        textAlign: 'center',
        margin: '0% 31%',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '2.5vw !important',
        lineHeight: '3vw !important',
        textAlign: 'center',
        margin: '0% 31%',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '2.5vw !important',
        lineHeight: '3vw !important',
        textAlign: 'center',
        margin: '0% 31%',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '3vw !important',
        lineHeight: '4vw !important',
        textAlign: 'center',
        margin: '0% 20%',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '7vw !important',
        lineHeight: '8vw !important',
        margin: '0% 13%',
      },
    },

    '& .sectionHeading h3.h4-fontxl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: '4.7vw !important',
        lineHeight: '5.5vw !important',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '6vw !important',
        lineHeight: '6.5vw !important',
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: '6vw !important',
        lineHeight: '7vw !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '11vw !important',
        lineHeight: '12vw !important',
      },
    },

    '& .solutionsCarousel h2.h3-fontxl': {
      [theme.breakpoints.up('lg')]: {
        fontSize: '4.7vw !important',
        lineHeight: '5.5vw !important',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '6vw !important',
        lineHeight: '6.5vw !important',
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: '6vw !important',
        lineHeight: '7vw !important',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '11vw !important',
        lineHeight: '12vw !important',
      },
    },
    '& .customAlignmentMobile div.MuiGrid-root.MuiGrid-container': {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    '& .customAlignmentMobile h2.h3-fontl': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    '& .customAlignmentMobile h3.h4-fontm': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    '& .titleonlybanner div.MuiBox-root div.MuiBox-root div.MuiBox-root div.MuiBox-root': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .circle-carousel-content-container .circle-carousel-content-image h2': {
      [theme.breakpoints.up(600)]: {
        fontSize: '46px !important',
        lineHeight: '22px !important',
        letterSpacing: '-0.5px !important',
      },
      [theme.breakpoints.between(320, 450)]: {
        fontSize: '24px !important',
        lineHeight: '15px !important',
        letterSpacing: '-0.5px !important',
      },
    },
    '& .circle-carousel-content-container .circle-carousel-content-image div p': {
      [theme.breakpoints.up(600)]: {
        fontSize: '20px !important',
        lineHeight: '22px !important',
        letterSpacing: '-0.5px !important',
      },
      [theme.breakpoints.between(320, 450)]: {
        fontSize: '14px !important',
        lineHeight: '15px !important',
        letterSpacing: '-0.5px !important',
      },
    },
    '& .circle-carousel-content-container .circle-carousel-content div p': {
      [theme.breakpoints.up('lg')]: {
        fontSize: '20px',
        lineHeight: '22px',
        letterSpacing: '-0.5px',
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: '16px',
        lineHeight: '18px',
        letterSpacing: '-0.5px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '20px',
        lineHeight: '22px',
        letterSpacing: '-0.5px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '22px',
        letterSpacing: '-0.5px',
      },
    },
    '& .circle-carousel-content-container .circle-carousel-content div > div a': {
      [theme.breakpoints.down('sm')]: {
        padding: '0 30px',
        display: 'flex',
        justifyContent: 'center',
        lineHeight: '24px',
      },
    },
    // '& .MuiPaper-root': {
    //   background: 'transparent !important',
    // },
    '& .test.MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiDialog-container.MuiPaper-root': {
      background: '#ffffff',
    },
  };

  return (
    <div className='App'>
      <Router>
        <StencilTenant tenantId='6638b5108a0d1608d0421d83'>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <GlobalStyles styles={variantStyles} />
              <Header />
              <ScrollToTop />
              <Main />
              <Footer />
            </Provider>
          </ThemeProvider>
        </StencilTenant>
      </Router>
    </div>
  );
}

export default App;
