import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
//import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { crossSvg as closeSvg } from '../../assets/Images/Images';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    // height: '450px',
    // width: '750px',
  },
  videoContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    border: '1px solid rgba(0,0,0,.2)',
    boxShadow: '24px ',
    borderRadius: '.3rem',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.up('md')]: {
      //   height: '50%',
      width: '50%',
    },
  },
  videoPlayer: {
    padding: '0 5% 5% 5%',
    width: '100%',
    height: '100%',
    // [theme.breakpoints.down('md')]: {
    //     padding: '0 7% 7% 7%',
    // },
  },
  closeIcon: {
    background: `url(${closeSvg}) no-repeat`,
    color: '#333333 !important',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    border: 'none',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      width: '13px',
    },
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  closeIconContainer: {
    textAlign: 'right',
    padding: '2% 2% 0 0',
    [theme.breakpoints.down('md')]: {
      padding: '3% 3% 0 0',
    },
  },
}));

const Video = ({ open, handleClose, videoSrc, captions }) => {
  const classes = useStyles();
  return (
    <div>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        {`
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WLZJSJZ"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
      <Modal open={open} role='dialog' onClose={handleClose} aria-label='video player dialog' className={classes.modalContainer}>
        <Box className={classes.videoContainer}>
          <h2 className={classes.visuallyHidden}>video player</h2>
          {/* <CloseSharpIcon className={classes.closeIcon} onClick={handleClose} /> */}
          <div className={classes.closeIconContainer}>
            <button className={classes.closeIcon} onClick={handleClose} aria-label='close'>
              {/* <img src={closeSvg} alt='clsoe' onClick={handleClose} /> */}
            </button>
          </div>
          <video
            playsInline
            autoPlay={true}
            controls
            className={classes.videoPlayer}
            controlsList='nodownload'
            crossOrigin='anonymous'
            poster='https://gfc.target.com/elevatewebsiteproduction/elevatewebsite2022_video_poster.png'
          >
            <source src={videoSrc} type='video/mp4' />
            <track src={captions} kind='subtitles' srcLang='en' label='English' />
          </video>
        </Box>
      </Modal>
    </div>
  );
};
export default Video;
