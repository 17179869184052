import * as React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Video from '../../../Components/VideoPlayer/VideoPlayer';
import { Helmet } from 'react-helmet';

const styles = {
  topicName: {
    // fontWeight: 'bold',
    // fontFamily: 'HelveticaforTargetRegular',
    margin: 0,
    padding: 0,
    color: '#FFF',
    fontSize: '15px',
  },
  topicTitle: {
    padding: '20px 0 6px 0',
    margin: 0,
    fontSize: 20,
    // fontFamily: 'HelveticaforTargetBold',
    lineHeight: '1.2',
    color: '#ffffff',
  },
  topicBio: {
    padding: 0,
    margin: 0,
    // fontFamily: 'HelveticaforTargetRegular',
    fontSize: 16,
    color: '#ffffff',
  },
  image: {
    width: '100%',
    cursor: 'pointer',
  },
  disclaimer: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '15px',
    // fontFamily: 'HelveticaforTargetRegular',
  },
  imageTumbs: {
    border: 'none',
    padding: 0,
    background: 'transparent',
    '&:focus-visible': {
      outline: '2px dotted #FFF',
      outlineOffset: 2,
      borderRadius: 0,
    },
  },
};

const Videos = ({ classes, galleryVideos, event_year }) => {
  const [open, setOpen] = useState(false);
  const [url, setURL] = useState('');
  const [captions, setCaptions] = useState('');
  const mobile = useMediaQuery('(max-width:899px)');
  const [title, setTitle] = React.useState('');

  const handleClick = (videoObj) => {
    setURL(videoObj.videoUrl);
    setCaptions(videoObj.captions);
    setOpen(true);
    setTitle(videoObj.name + ' video');
  };
  const handleClose = () => {
    setOpen(false);
    setTitle('Gallery');
  };
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Grid container>
        {galleryVideos.videos.map((video) => (
          <Grid item lg={4} md={6} sm={12} padding={!mobile ? '1rem' : '1.5rem'} key={video.url}>
            <button className={classes.imageTumbs} onClick={() => handleClick(video)}>
              <img src={video.url} alt={video.title + ' play video'} className={classes.image} />
            </button>
            <h2 className={classes.topicTitle}>{video.title} </h2>
            <p className={classes.topicName}>{video.name}</p>
            {video.name1 ? <p className={classes.topicName}>{video.name1}</p> : null}
          </Grid>
        ))}

        <Video open={open} handleClose={handleClose} videoSrc={url} captions={captions} />
      </Grid>
      {/* <div style={{ textAlign: 'center' }}>{event_year === 2023 && <h3 style={{ color: '#FFF' }}>Recordings from Target Multiplai 2023 coming soon.</h3>}</div> */}

      {/* <div
        style={{
          borderTop: "2px solid #CC0000",
          width: mobile ? "16%" : "5%",
          margin: mobile ? "40px auto 50px auto" : "70px auto ",
        }}
      ></div>
      <div className={classes.disclaimer}>
        <Typography variant="span">
          Disclaimer: Closed captions for these videos will be updated shortly
        </Typography>
      </div> */}
    </>
  );
};

export default withStyles(styles)(Videos);
