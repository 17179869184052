import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import Images from './Images/Images';
import Videos from './Videos/Videos';

const styles = {
  activeImagesStyles: {
    fontSize: 22,
    color: '#ffffff',
    // fontFamily: 'HelveticaforTargetBold',
    cursor: 'pointer',
    border: 'none',
    background: '#ffffff',
    fontWeight: 600,
    '&:focus-visible': {
      outline: '2px dotted #FFF',
      borderRadius: 0,
    },
  },
  imagesStyles: {
    fontSize: 22,
    color: '#ffffff',
    // fontFamily: 'HelveticaforTargetRegular',
    cursor: 'pointer',
    border: 'none',
    background: '#ffffff',
    fontWeight: 300,
    '&:focus-visible': {
      outline: '2px dotted #FFF',
      borderRadius: 0,
    },
  },
};

const GalleryTabs = ({ classes, galleryImages, galleryVideos, event_year }) => {
  const [imagesTab, setImages] = useState(1);
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'prod') {
      document.getElementById('imagestab').addEventListener('click', function () {
        // eslint-disable-next-line no-undef
        gtag('event', 'GalleryImage_click', {
          event_category: 'Button Click',
          event_label: 'Images Tab',
          button_type: 'CTA',
        });
      });
      document.getElementById('videostab').addEventListener('click', function () {
        // eslint-disable-next-line no-undef
        gtag('event', 'GalleryVideo_click', {
          event_category: 'Button Click',
          event_label: 'Videos Tab',
          button_type: 'CTA',
        });
      });
    }
  }, []);
  return (
    <Grid>
      <Grid container style={{ marginBottom: 40, paddingLeft: '1rem' }}>
        <button
          className={imagesTab === 0 ? classes.activeImagesStyles : classes.imagesStyles}
          style={{ background: 'transparent' }}
          onClick={() => setImages(0)}
          id={'imagestab'}
        >
          Images
        </button>
        <Typography sx={{ color: '#cc0000', fontWeight: '600', m: 2 }}>|</Typography>
        <button
          className={imagesTab === 1 ? classes.activeImagesStyles : classes.imagesStyles}
          style={{ background: 'transparent' }}
          onClick={() => setImages(1)}
          id={'videostab'}
        >
          Videos
        </button>
      </Grid>
      {imagesTab === 0 ? <Images galleryImages={galleryImages} event_year={event_year} /> : <Videos galleryVideos={galleryVideos} event_year={event_year} />}
    </Grid>
  );
};

export default withStyles(styles)(GalleryTabs);
