import * as React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Dialog, DialogContent, DialogContentText, Slide, useMediaQuery } from '@mui/material';
// import dots_bg from '../../Images/dots_bg.png';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  closeIconContainer: {
    //padding: '40px',
    // height: '80px',
  },
  closeIcon: {
    float: 'right',
    right: '20px',
    top: '10px',
    position: 'absolute',
    fontSize: '30px !important',
    cursor: 'pointer',
    color: '#333333 !important',
    border: 'none',
    padding: '3px',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      //fontSize: '40px !important',
      right: '10px',
      // top: '50px',
    },
  },
  hidBox: {
    top: '10%',
    position: 'relative',
    transition: 'all .3s ease-out',
    background: '#FFF',
    height: '100%',
  },
  showBox: {
    top: '-99%',
    position: 'relative',
    transition: 'all .3s ease-out',
    background: '#FFF',
    height: '100%',
    padding: '0 30px',
    borderRadius: '10px',
  },
  speakerDetail: {
    fontSize: '15px !important',
  },
  learnMoreLink: {
    fontWeight: '600 !important',
    color: '#000000 !important',
    padding: '0 !important',
  },
  speakerName: {
    fontWeight: '600 !important',
    fontSize: '20px !important',
    // padding: '18px 0 6px 0',
    color: '#FFF',
    padding: (props) => (props.mobile ? '0 0 6px 0' : '18px 0 6px 0'),
  },
  speakerNameInDialog: {
    fontWeight: '600 !important',
    fontSize: '20px !important',
    // padding: '18px 0 6px 0',
    color: '#000000',
    paddingBottom: '10px',
  },
  speakerProfession: {
    fontWeight: '300 !important',
    color: '#FFF ',
    fontSize: '16px !important',
    // width: '250px !important',
    // width: (props) => (props.mobile ? 'auto' : '250px !important'),
  },
  speakerProfessionInDialog: {
    fontWeight: '300 !important',
    color: '#000000 ',
    fontSize: '16px !important',
  },
  speakerImg: {
    //height: '300px',
    // width: '100%',
    cursor: 'pointer',
    // width: '200%',
    height: 250,
    // margin: '0px 0 0 -160px',
    '&:hover ': {
      // opacity: 0.5,
      // backgroundImage: `url(${dots_bg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '170%',
      height: '280px',
    },
  },
  dialogImg: {
    margin: '0 auto',
    marginBottom: '-6px',
    padding: '18px 0 0 0',
  },
  dialogBox: {
    //height: '30%',
    width: '25%',
    margin: '0 auto',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .MuiPaper-root': {
      // borderRadius: '10px',
      // borderBottom: '8px solid #CC0000',
      background: '#FFF !important',
    },
  },
  main: {
    padding: '15% 5%',
    textAlign: 'center',
    width: '350px',
    [theme.breakpoints.down('md')]: {
      padding: '1rem',
    },
    '&:focus-visible': {
      borderRadius: 50,
      outline: '2px dotted #FFF',
      outlineOffset: 2,
    },
  },
  cropImg: {
    //backgroundImage: `url(${cropImg})`,
    // width: 300,
    // height: 300,
    // overflow: 'hidden',
    // margin: '0 auto',
    //backgroundPosition: 'center',
    /* Make the background image cover the area of the <div>, and clip the excess */
    //backgroundSize: 'cover',
  },
  imgBtn: {
    height: '290px',
    '&:focus-visible': {
      opacity: 0.8,
      outline: '2px dotted #FFF',
      // outlineOffset: 2,
      border: '1px solid red',
    },
  },
  speakerInfo: {
    height: '20px',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  dialogImgContainer: {
    // backgroundColor: '#CC0000',
    textAlign: 'center',
  },
}));
const AboutSpeaker = ({ fullName, designation, organisation, aboutSpeaker, classes, thumbnail, isDialog, handleClickOpen }) => {
  return (
    <div className={thumbnail ? classes.speakerInfo : null}>
      <Typography variant='h2' align='center' className={isDialog ? classes.speakerNameInDialog : classes.speakerName}>
        {fullName}
      </Typography>
      <Typography variant='body2' align='center' className={isDialog ? classes.speakerProfessionInDialog : classes.speakerProfession}>
        {designation}
      </Typography>
      <Typography variant='body2' align='center' className={isDialog ? classes.speakerProfessionInDialog : classes.speakerProfession}>
        {organisation}
      </Typography>
      <Typography variant='body2' align='center' className={classes.speakerDetail} style={thumbnail ? {} : { paddingTop: '20px' }}>
        {aboutSpeaker !== undefined && aboutSpeaker.split('|')[0]}
        {/* {aboutSpeaker && aboutSpeaker.split('|').length > 1 && ? aboutSpeaker.split('|')[1] : aboutSpeaker.split('|')[0]} */}
      </Typography>
      {/* {isDialog ? null : (
                <button
                    aria-label={`Read more about ${fullName}`}
                    style={{ border: 'none', backgroundColor: 'transparent' }}
                    className={classes.imgBtn}
                    onClick={handleClickOpen}
                >
                    <Typography style={{ color: '#FFF', cursor: 'pointer' }}>Read More {`>`}</Typography>
                </button>
            )} */}
    </div>
  );
};
const SpeakerCard = (props) => {
  const { name: fullName, company: organisation } = props.speakerDetail.user;
  const { designation, engagement_image: speakerImg, bio: aboutSpeaker } = props.speakerDetail.meta;
  const mobile = useMediaQuery((theme) => theme.breakpoints.up('xs') && theme.breakpoints.down('sm'));
  const tablet = useMediaQuery((theme) => theme.breakpoints.up('sm') && theme.breakpoints.down('md'));
  const classes = useStyles({ tablet, mobile });
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.main}>
      <Dialog
        open={open}
        className={classes.dialogBox}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        role='dialog'
        aria-modal='true'
        aria-label={`${fullName} bio`}
      >
        <div className={classes.closeIconContainer}>
          <button className={classes.closeIcon} onClick={handleClose} aria-label='close'>
            <CloseSharpIcon onClick={handleClose} />
          </button>
        </div>
        <div className={classes.dialogImgContainer}>
          <img src={speakerImg} alt={fullName} className={classes.dialogImg} style={{ width: mobile ? '90%' : tablet ? '25%' : '90%' }} />
        </div>
        <DialogContent style={{ padding: '0 30px 30px 30px' }}>
          <DialogContentText id='alert-dialog-slide-description'>
            <AboutSpeaker
              fullName={fullName}
              designation={designation}
              organisation={organisation}
              aboutSpeaker={aboutSpeaker}
              classes={classes}
              thumbnail={false}
              isDialog={true}
            />
          </DialogContentText>
        </DialogContent>
        <div style={{ backgroundImage: '-webkit-linear-gradient(left,rgb(204,0,0), rgb(189,0,119))', height: '10px' }}></div>
      </Dialog>
      <button
        style={{ border: 'none', backgroundColor: 'transparent' }}
        className={classes.imgBtn}
        onClick={handleClickOpen}
        aria-label={`photo of ${fullName}`}
      >
        {/* <div className={classes.cropImg} onClick={handleClickOpen}> */}
        <img src={speakerImg} alt={''} className={classes.speakerImg} onClick={handleClickOpen} />
        {/* </div> */}
      </button>
      <AboutSpeaker
        fullName={fullName}
        designation={designation}
        organisation={organisation}
        classes={classes}
        thumbnail={true}
        handleClickOpen={handleClickOpen}
      />
    </div>
  );
};

export default SpeakerCard;
