import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import styles from './AgendaCustom.module.css';
import { Box, Typography } from '@mui/material';
const AgendaForDesktop = () => {
  return (
    <TableContainer className={styles['agenda-table']}>
      <Table aria-label='simple table'>
        <TableBody>
          <TableRow className={styles['time-container']}>
            <TableCell>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  p: '5px 16px',
                }}
              >
                8.30 am to 9.30 am
              </Typography>
            </TableCell>
            <TableCell>
              {' '}
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Breakfast & Registration
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={6} sx={{ borderRight: '1px solid #8B7A83' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  p: '5px 16px',
                }}
              >
                10.00 am to 12.00 pm
              </Typography>
            </TableCell>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, pt: 3, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Andrea Zimmerman'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>{'President, Target in India'}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 3, sm: 2 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Welcome to Multiplai
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Brad Thompson & Nidhi Sen'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>
                  {'SVP - Technology, Target | Sr. Director - Technology, Target'}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Moving Beyond PoCs – Scaling GenAI to Impact
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Dr Manish Gupta'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>{'Director - Research, Google'}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                The Transformative Power of AI and Open Challenges
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Prasad Deshpande'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>{'Senior Staff Software Engineer, Databricks'}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                From Data Silos to AI Success through Scalable Pipelines
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{' Ramya Raghunathan & Sharad Limaye'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>
                  {'Vice President - Data Sciences, Target | Sr Director - Data Sciences, Target in India'}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Case Study: The Science of Forecasting and Inventory Management
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 3, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Satnam Singh'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>{'Chief Data Scientist, Acalvio Technologies'}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                AI-Powered Deception for Identity Protection
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={styles['time-container']}>
            <TableCell>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  p: '5px 16px',
                }}
              >
                12.15 pm to 12.30 pm
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Coffee Break
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={2} sx={{ borderRight: '1px solid #8B7A83' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  p: '5px 16px',
                  minWidth: '330px',
                }}
              >
                12.30 pm to 1.15 pm
              </Typography>
            </TableCell>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, pt: 3, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Abhishek Mishra'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>{'Senior Manager - Data, Snowflake'}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 2 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Workshop 1: ML Model Operations and Observability
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 3, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Aashimi Bhatia & Ritesh Rajani'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>
                  {'Principal Data Scientist, Target in India | Principal AI Engineer, Target in India'}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Workshop 2: High Performance Computing with Spark
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow className={styles['time-container']}>
            <TableCell>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  p: '5px 16px',
                }}
              >
                1.30 pm to 3.00 pm
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Lunch | Demos | Networking
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={4} sx={{ borderRight: '1px solid #8B7A83' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  p: '5px 16px',
                }}
              >
                3.00 pm to 4.30 pm
              </Typography>
            </TableCell>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, pt: 3, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Raghavan Subramanian'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>
                  {'Associate Vice President and Head, Infosys Tennis Platform'}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 2 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Advantage Tennis through AI
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Melissa Ludack & Mudit Ranjan Pati'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>
                  {'VP - Data Sciences, Target | Sr Director - Data Sciences, Target in India'}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Case Study: Delighting Guests with Personalization using Reinforcement Learning
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Arvind Jayaprakash'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>{'Senior Vice President - Technology Glance'}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Engineering for machine learning: What a decade of experience has taught us
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 1, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Vaibhav Khandelwal'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>{'Co-founder and CTO, Shadowfax'}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                AI to Enhance Last-Mile Delivery Experience
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            {/* <TableCell sx={{ borderRight: '1px solid #8B7A83' }}>
              <Box sx={{ pb: 3, display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 22, color: '#fff' }}>{'Narasimha Medeme'}</Typography>
                <Typography sx={{ fontWeight: '500', fontSize: 16, color: '#8B7A83' }}>{'Head - Data Science, MakeMyTrip'}</Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pl: 1,
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                Personalized Ranking Search Systems
              </Typography>
            </TableCell> */}
          </TableRow>
          <TableRow className={styles['time-container']}>
            <TableCell>
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  p: '5px 16px',
                }}
              >
                4.30 pm to 5.00 pm
              </Typography>
            </TableCell>
            <TableCell>
              {' '}
              <Typography
                sx={{
                  fontSize: { sm: 22, xs: 17 },
                  fontWeight: 'bold',
                  color: '#FFF',
                  pt: { xs: 1, sm: 0 },
                  pb: { xs: 1, sm: 0 },
                }}
              >
                High Tea | Demos | Networking
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AgendaForDesktop;
