import { Grid } from '@mui/material';
import NavBar from '../Components/NavBar/NavBar';

const Header = () => {
  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <NavBar />
      </Grid>
    </Grid>
  );
};

export default Header;
