import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    backgroundColor: '#1A1A1A',
    color: '#FFF !important',
    position: (props) => (props.isFaq ? 'absolute' : 'relative'),
    bottom: '0',
    [theme.breakpoints.down('md')]: {
      bottom: 'auto',
    },
  },
  socialIcons: {
    color: '#939393',
    borderRadius: '50%',
    padding: '5px',
    fontSize: '2.3rem !important',
    margin: '10px',
    border: '1px solid',
  },
  footerLinks: {
    color: '#939393 !important',
    textDecoration: 'none',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    [theme.breakpoints.down('md')]: {
      padding: '20px 20px 10px 20px',
    },
  },
  socialIconsContainer: {
    paddingTop: '20px',
  },
  links: {
    '&:focus-visible': {
      boxShadow: 'none',
      outline: '2px dotted #FFF',
      outlineOffset: 2,
    },
  },
  linkDivider: {
    color: '#717171',
    margin: '0 10px',
    fontWeight: 600,
  },
}));
export default function Footer({ isPrivacy, isFaq }) {
  const classes = useStyles({ isPrivacy, isFaq });
  return (
    <footer>
      <Grid container className={classes.footerContainer}>
        <Grid item md={0.8}></Grid>
        <Grid item md={2.2} xs={12} className={classes.footerLinks} sx={{ justifyContent: 'left' }}>
          <a
            href='https://www.linkedin.com/company/target/posts/?feedView=all'
            target='_blank'
            // rel='noopener noreferrer'
            aria-label={'Follow us on LinkedIn'}
            className={classes.links}
            rel='noreferrer'
          >
            <LinkedInIcon className={classes.socialIcons} />
          </a>
          <a
            href='https://instagram.com/teamtargetinindia'
            target='_blank'
            // rel='noopener noreferrer'
            aria-label={'Follow us on Instagram'}
            className={classes.links}
            rel='noreferrer'
          >
            <InstagramIcon className={classes.socialIcons} />
          </a>
          <a
            href='https://x.com/TeamTargetIndia'
            target='_blank'
            // rel='noopener noreferrer'
            aria-label={'Follow us on Twitter'}
            className={classes.links}
            rel='noreferrer'
          >
            <XIcon className={classes.socialIcons} />
          </a>
        </Grid>

        <Grid item md={4} xs={12} className={classes.footerLinks} sx={{ justifyContent: { xs: 'left', md: 'right' } }}>
          <Link to='/termsandconditions' style={{ color: '#939393', textDecoration: 'none', fontSize: '15px' }} className={classes.links}>
            Terms and Conditions
          </Link>
          <span className={classes.linkDivider}>|</span>
          <Link to='/privacypolicy' style={{ color: '#939393', textDecoration: 'none', fontSize: '15px' }} className={classes.links}>
            Privacy Policy
          </Link>
          <span className={classes.linkDivider}>|</span>
          <a href='mailto:multiplai@target.com' style={{ color: '#939393', textDecoration: 'none', fontSize: '15px' }} className={classes.links}>
            Contact Us
          </a>
        </Grid>
        <Grid item md={2}></Grid>
        <Grid item md={2} xs={12} className={classes.footerLinks}>
          &copy; 2024 Target Brands, Inc.
        </Grid>
      </Grid>
    </footer>
  );
}
