import SpeakerCard from './SpeakerCard';
import { Grid, useMediaQuery } from '@mui/material';

const RenderSpeakers = ({ speakersArray }) => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.up('xs') && theme.breakpoints.down('sm'));
  return (
    <Grid container sx={{ justifyContent: mobile ? 'center' : 'auto', padding: { md: '0 6%', lg: '0 6%' } }}>
      {speakersArray?.map((speaker, index) => (
        <Grid item key={index} lg={3} md={3} sm={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <SpeakerCard speakerDetail={speaker} />
        </Grid>
      ))}
    </Grid>
  );
};

export default RenderSpeakers;
